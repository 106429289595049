import React from 'react';
import {
    Button, Stack, styled
} from '@mui/material';
import Components from '../../components';
import actions from '../../apis/clientActionHandler';
import { useDispatch, useSelector } from '../../redux/store';
import { useHistory } from 'react-router-dom';

const Root = styled('div')(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    padding: theme.spacing(1)
}))

const FormFooter = ({ UIMetaData = {}, formData, handleOnSubmit }) => {
    const { id: formName, layout } = UIMetaData;
    const { buttons } = layout && layout.footer;
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const history = useHistory();
   
    const handleButtonClick = (btnOnClickEvent) => {
        if (!btnOnClickEvent) return;
        
        btnOnClickEvent.actions.forEach(action => {
            if (action === "saveData") {
                handleOnSubmit();
            } else {
                actions[action](dispatch, state, history)({ formName });
            }
        });
    }

    return (
        <Root>
            <Stack direction="row" spacing={1} alignItems="flex-start">
                {buttons && buttons.length > 0 && buttons.map(btn => {
                    const WIAButton = Components[btn.type];
                    const btnOnClickEvent = btn.events && btn.events.onClick && btn.events.onClick;
                    return <WIAButton {...btn} onClick={
                        (evt) => { 
                            evt.preventDefault();
                            handleButtonClick(btnOnClickEvent) 
                        }
                    } />
                })}
            </Stack>
        </Root>
    );
};

export default FormFooter;
