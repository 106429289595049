import React, { cloneElement } from "react";
import { Divider, styled } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Header from "./Header";
import Footer from "./Footer";
import { prepModel } from '../../utils/util';
import actions from '../../apis/clientActionHandler';
import { useDispatch, useSelector } from '../../redux/store';
import { useHistory } from 'react-router-dom';

const Root = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
}))

const FormLayout = ({ data = {}, children }) => {
    console.log("FormLayout ", data);
    const { id: formName, layout } = data;
    const scrollContentStyles = (layout && layout.styles) ? layout.styles.scrollContent : { minHeight: '100%' };
    const model = prepModel(data);

    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const history = useHistory();

    const getValidationSchema = () => {
        const validationSchema = { ...model.validationSchema };
        
        if (model.subFormNamespaces.length > 0) {
            model.subFormNamespaces.forEach(namespace => {
                if (validationSchema[namespace]) {
                    validationSchema[namespace] = Yup.object().shape(validationSchema[namespace])
                }
            });
        }
           
        return Yup.object(validationSchema);
    }
    
    return (
        <Root>
            <Formik
                initialValues={model.dataModel}
                validationSchema={getValidationSchema()}
                onSubmit={async (values, {
                    setErrors,
                    setStatus,
                    setSubmitting
                }) => {
                    try {
                        actions.saveData(dispatch, state, history)({ formName, data: values });
                        console.log("SUCCESS ", values)
                        setStatus({ success: true });
                        setSubmitting(false)
                    } catch (error) {
                        console.log("ERRROR ", error)
                        setStatus({ success: false });
                        setSubmitting(false);
                        setErrors({ submit: error.message })
                    }
                }}
            >
                {({
                    values,
                    isSubmitting,
                    isValidating,
                    handleSubmit,
                    setFieldValue
                }) => (
                    <div style={{ minHeight: '100%' }}>
                        <Form>
                            <Header UIMetaData={data} />
                            <PerfectScrollbar
                                style={{ ...scrollContentStyles }}
                                options={{ suppressScrollY: false, suppressScrollX: true }}
                            >
                                {cloneElement(children, {
                                    model,
                                    isSubmitting,
                                    setFieldValue
                                })}
                            </PerfectScrollbar>
                            <Divider />
                            <Footer
                                UIMetaData={data}
                                formData={values}
                                isSubmitting
                                handleOnSubmit={handleSubmit} />
                        </Form>
                    </div>
                )}
            </Formik>
        </Root>
    )
}

export default FormLayout;