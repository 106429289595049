import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        "&:hover":{
            cursor: "pointer",
            backgroundColor: "#EFEFEF"
        }
    },
    cardContent: {
        padding: '10px 14px 10px 14px !important'
    },
    vendorName: {
        fontSize: 14,
        fontWeight: 600
    },
    vendorCategory: {
        fontSize: 12,
        fontWeight: 400
    }
}))

export default function ListCard({ UIMetaData, details }) {
    const classes = useStyles();
    const history = useHistory();
    const { id: dataId } = useParams();

    const { entity, id: uimodelId } = UIMetaData;

    const isSeleced = details.id === parseInt(dataId);
    
    return (
        <Card variant='outlined' className={classes.container} onClick={()=> history.push(`/app/main/uimodel/${uimodelId}/${entity}/${details.id}`)} style={{ backgroundColor: isSeleced ? "#EFEFEF" : ""}}>
            <CardContent className={classes.cardContent}>
                <Typography className={classes.vendorName} color="text.secondary">
                    {details.companyName}
                </Typography>
                <Typography className={classes.vendorCategory} color="text.secondary">
                    {details.category}
                </Typography>
            </CardContent>
        </Card>
    )
}