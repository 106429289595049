import { Checkbox, Grid, TextField, List, ListItem, Stack, Typography, Chip, Button, ButtonGroup } from '@mui/material';
import React, { useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    listItem: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#0080000a'
        }
    }
}))

export default function ItemSelectPopup({ items = [],selectedItems, onClose, onClickItemSelect }) {
    const classes = useStyles();
    const [checkedItems, setCheckedItems] = React.useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    
    const filteredItems = items.filter(item => item.name.includes(searchTerm));

    const handleToggle = (value) => () => {
        const currentIndex = checkedItems.indexOf(value);
        const newChecked = [...checkedItems];

        value.quantity = 0

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedItems(newChecked);
    };


    const handleQuantity = (updatedItem, value) => {
        setCheckedItems(
            checkedItems.map((item) => {
                if (item.id === updatedItem.id) {
                    return {
                        ...item,
                        quantity: value
                    }
                }
                return item;
            })
        )        
    }

    const handleClickAddItems = () => {
        onClickItemSelect(checkedItems);
        onClose();
    }

    return (
        <Grid container spacing={2} style={{ padding: 10 }}>
            <Grid item lg={6}>
                <TextField 
                    fullWidth
                    size='small'
                    InputProps={{
                        endAdornment: <SearchIcon style={{ color: '#cccccc'}} />,
                    }}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <List sx={{ height: 450, position: 'relative', overflow: 'auto' }}>
                    {
                        filteredItems.map((item) => {
                            return (
                                <ListItem
                                    key={item.id}
                                    onClick={handleToggle(item)}
                                    className={classes.listItem}
                                    secondaryAction={
                                        <Checkbox
                                            edge="end"
                                            onChange={handleToggle(item)}
                                            checked={checkedItems.filter(itemA => itemA.id === item.id).length > 0}
                                            icon={<CheckCircleOutlineIcon />}
                                            checkedIcon={<CheckCircleIcon style={{ color: 'green'}}/>}
                                        />
                                    }>
                                    <Stack direction='column'>
                                        <Typography variant='subtitle2'>{item.name}</Typography>
                                        <Typography variant='body2' style={{ fontSize: 12 }}>
                                            {item.description.slice(0, 50) + " ..."}
                                        </Typography>
                                    </Stack>
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Grid>
            <Grid item lg={6}>
                <Stack direction="row" spacing={2}>
                    <Typography variant='h6'>
                        Selected Items
                    </Typography>
                    <Chip variant='outlined' style={{ fontSize: 14, fontWeight: 400, minWidth: 40 }} label={checkedItems.length} />
                </Stack>
                <List sx={{ height: 450, position: 'relative', overflow: 'auto' }}>
                {
                        checkedItems.map((item) => {
                            return (
                                <ListItem
                                    key={item.id}
                                    className={classes.listItem}
                                    // secondaryAction={
                                    //     // <ButtonGroup size="small">
                                    //     //     {/* <Button onClick={() => (item.quantity !==0) && handleQuantity(item, -1)}>-</Button> */}
                                    //     //     <input type="number" value={item.quantity} onChange={(e) => handleQuantity(item, parseInt(e.target.value))}/>
                                    //     //     {/* <Button onClick={() => handleQuantity(item, +1)}>+</Button> */}
                                    //     // </ButtonGroup>
                                    // }>
                                    >
                                    <Stack direction='row' spacing={2}>
                                        <Typography variant='subtitle2' style={{ width: '50%', alignSelf: 'center' }}>{item.name}</Typography>
                                        <TextField
                                    variant="outlined"
                                    size="small"
                                    value={item.quantity}
                                    type="number"
                                    onChange={(e) => handleQuantity(item, parseInt(e.target.value))}
                                />
                                    </Stack>
                                </ListItem>
                            )
                        })
                    }
                </List>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" size="medium" onClick={() => handleClickAddItems()}>Add Items</Button>
                    <Button variant="outlined" size="medium" color="secondary" onClick={() => onClose()}>Cancel</Button>
                </Stack>
            </Grid>
        </Grid>
    )
}