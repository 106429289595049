import React from 'react';
import { Grid } from '@material-ui/core';
import ActionBar from './ActionBar';
import BulkActionBar from './BulkActionBar';
import Filter from './Filter';
import PermissionGate from '../../components/auth/PermissionGate';

function VendorList({ UIMetaData = {}, children, selectionModel = [], permissionSet }) {

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item lg={12}>
                    {selectionModel.length > 0 ?
                        <PermissionGate permissionSet={permissionSet}>
                            <BulkActionBar
                                UIMetaData={UIMetaData.layout.bulkActionBar}
                                permissionSet={permissionSet}
                            />
                        </PermissionGate>
                        :
                        <PermissionGate permissionSet={permissionSet}>
                        <ActionBar
                            UIMetaData={UIMetaData.layout}
                            permissionSet={permissionSet}
                        />
                        </PermissionGate>
                    }
                </Grid>
                <PermissionGate permissionSet={permissionSet}>
                    <Grid item lg={2}>
                        <Filter
                            UIMetaData={{ filters: UIMetaData.layout.filters }}
                            permissionSet={permissionSet}
                        />
                    </Grid>
                </PermissionGate>
                <Grid item lg={10}>
                    {children}
                </Grid>
            </Grid>
        </div>
    )
}

export default VendorList;