import login from './login.json';
import vendorList from './vendorList.json';
import userList from './userList.json';
import roleList from './roleList.json';
import vendorDetailView from './vendorDetailView.json';
import orgernizationProfileForm from './vendorOrgernizationForm.json';
import rfqList from './rfqList.json';
import createVendor from './createVendor.json';
import createUser from './createUser.json';
import rfqForm from './rfqForm.json';
import changePasswordForm from './changePasswordForm.json';


const UIModels = {
    login,
    vendorList,
    userList,
    roleList,
    vendorDetailView,
    orgernizationProfileForm,
    rfqList,
    createVendor,
    createUser,
    rfqForm,
    changePasswordForm
}

export default UIModels;