import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, FormControlLabel, Checkbox, FormControl, Select, TextField } from '@mui/material';

const useStyles = makeStyles(() => ({
    filterTextBox: {
        marginTop: 5
    }
}))

export default function FilterOption({ FilterOption }) {
    const classes = useStyles();
    const [active, setActive] = useState();

    return (
        <Grid item>
            <FormControlLabel
                control={
                    <Checkbox
                        name="checked"
                        color="primary"
                        onChange={() => setActive(!active)}
                    />
                }
                label={FilterOption.type}
            />
            {active && <div>
                <FormControl variant="outlined" fullWidth size={"small"} className={classes.formControl}>
                    <Select
                        native
                        placeholder="Options"
                        inputProps={{
                            name: 'age',
                            id: 'outlined-age-native-simple',
                        }}
                    >
                        {
                            FilterOption && FilterOption.options && FilterOption.options.map(data => {
                                return <option value={data}>{data}</option>
                            })
                        }
                    </Select>
                </FormControl>
                <TextField className={classes.filterTextBox} id="outlined-basic" variant="outlined" size="small" fullWidth />
            </div>}
        </Grid>
    )
}
