import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';
import navItemsMain from '../../helpers/datasource/UIModels/navItemsMain.json';
import navItemsSettings from '../../helpers/datasource/UIModels/navItemsSettings.json';

const AuthGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  console.log("USER ", user)
  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  
  // const pathname = window.location.pathname;
  // const selectedPath = [...navItemsMain, ...navItemsSettings].find(item => item.href.includes(pathname));
  // console.log("AUTH GUARD ", selectedPath, pathname)

  // if (selectedPath) {
  //   // check if route is restricted by role
  //   console.log("AUTH GUARD ", selectedPath.roles, user)
  //   if (selectedPath.roles && !selectedPath.roles.includes("*") && !selectedPath.roles.includes(user.role)) {
  //     // role not authorised so redirect to home page
  //     return <Redirect to={{ pathname: '/404'}} />
  //   }
  // } else {
  //   return <Redirect to={{ pathname: '/404'}} />
  // }

  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
