import { Fragment } from 'react';
import Layouts from '../../../layouts';
import WIALCPFormRenderer from '../../../components/form/WIALCPFormRenderer';

export default function FormBuilder({ data = {} }) {
    const FormLayout = (data.layout && data.layout.id) ? Layouts[data.layout.id] : Fragment
    const layoutStyles = (data.layout && data.layout.styles) ? data.layout.styles.layout : { minHeight: '100%' } 
    return (
      <div style={{ ...layoutStyles }}>
        <FormLayout data={data}>
            <WIALCPFormRenderer />
        </FormLayout>
      </div>
    )
  }