import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import {
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    FormHelperText,
    Checkbox,
    ListItemText,
    Box,
    Chip
} from "@mui/material";
import axios from '../../utils/axios';

export default function WIAMultiSelectCheckmarksComponent(props) {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const [field, meta] = useField(props.name);
    const { id, dataModelKey, dataModel, filter, columns = [], dictionaryApi, label, setFieldValue, ...rest } = props;
    const selectedValues = field.value;

    const [options, setOptions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const apiPath = `http://localhost:3000${dictionaryApi ? dictionaryApi.path : '/api/dictionaryData'}`;
            const httpType = dictionaryApi ? dictionaryApi.type : "post";
            const response = await axios[httpType](apiPath, { dataModel, filter, columns });
			const { data } = response.data;
            
            setOptions(data)
        }

        fetchData();
    }, []);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        const isOptionExist = selectedValues.find(_selectedValue => _selectedValue[dataModelKey] === value[dataModelKey]);
        let newSelections = [];
        if (isOptionExist) 
            newSelections = selectedValues.filter(_selectedValue => _selectedValue[dataModelKey] !== value[dataModelKey]);
        else
            newSelections = [...selectedValues, value];

        setFieldValue(id, newSelections)
    };
    
    return (
        <div>
            <FormControl variant="outlined" style={{ width: '100%' }} size="small">
                <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
                <Select
                    {...rest}
                    {...field}
                    labelId="demo-multiple-checkbox-label"
                    label={label}
                    id="demo-multiple-checkbox"
                    value={selectedValues}
                    onChange={handleChange}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value[dataModelKey]} label={value.displayValue} />
                          ))}
                        </Box>
                      )}
                    MenuProps={MenuProps}
                    fullWidth
                >
                    {options ? options.map(item => createCheckboxOption(dataModelKey, item, selectedValues)) : []}
                </Select>
                <FormHelperText>{meta.touched && meta.error}</FormHelperText>
            </FormControl>
        </div>
    );
}

function createCheckboxOption(key, option, selectedOptions) {
    return <MenuItem key={key} value={option}>
        <Checkbox checked={selectedOptions.find(_selectedOption => _selectedOption[key] === option[key])} />
        <ListItemText primary={option.displayValue} />
    </MenuItem >
}
