import SignInCenter from "./login/SignInCenter";
import SignInSide from "./login/SignInSide";
import SignInExpandRight from "./login/SignInExpandRight";
import ListLayout1 from "./ListLayout";
import TabLayout1 from "./TabLayout";
import FormLayout1 from "./FormLayout";
import DetailViewLayout1 from './DetailViewLayout';

const Layouts = {
    SignInCenter,
    SignInSide,
    SignInExpandRight,
    ListLayout1,
    TabLayout1,
    FormLayout1,
    DetailViewLayout1
}

export default Layouts;