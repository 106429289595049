import JWTLogin from "./auth/JWTLogin";
import Auth0Login from "./auth/Auth0Login";
import WIALCAPTable from "./WIALCAPTable";
import { 
    WIALCPTextButton, 
    WIALCPIconButton, 
    WIALCPIconButtonDefault, 
    WIALCPTextIconButton 
} from "./WIALCPButtons";
import WIALCPMenuButton from './WIALCPMenuButton';
import { 
    WIATextFieldComponent, 
    WIATextMultilineFieldComponent, 
    WIASelectFieldComponent,
    WIAMultiSelectCheckmarksComponent,
    WIADatePicker,
    WIAPasswordFieldComponent,
    WIANumberFieldComponent
} from "./form/WIALCPFormInputs";
import ItemList from "./form/itemList";
import WIALCPDataDictionary from './form/WIALCPDataDictionary';
import WIALCPFormRenderer from "./form/WIALCPFormRenderer";
import { WIALCPPanel } from "./widgets";

const components = {
    "JWTLogin": JWTLogin,
    "Auth0Login": Auth0Login,
    WIALCAPTable,

    //Buttons
    WIALCPTextButton,
    WIALCPIconButton,
    WIALCPIconButtonDefault,
    WIALCPTextIconButton,
    WIALCPMenuButton,

    //Form Renderer
    WIALCPFormRenderer,

    //Form Inputs
    'text-field': WIATextFieldComponent,
    'number-field': WIANumberFieldComponent,
    'textarea': WIATextMultilineFieldComponent,
    'select': WIASelectFieldComponent,
    'multiselect-checkbox': WIAMultiSelectCheckmarksComponent,
    'date-picker': WIADatePicker,
    'item-list': ItemList,
    'password-field': WIAPasswordFieldComponent,
    'dictionary': WIALCPDataDictionary,

    //Widgets
    'Panel': WIALCPPanel
}

export default components;