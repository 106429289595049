import axios from '../utils/axios';
var pluralize = require('pluralize')
import datasource from '../helpers/datasource/index';

let baseUrl = `http://localhost:3030/api/`;

export const entityAPIService = {
    getAll,
    getById,
    create,
    update,
    delete: _delete,
    getByFilter
};

function getAll(entity) {
    const apiEndpoint = baseUrl + pluralize.plural(entity);
    console.log("GET ALL CALLED ", datasource, entity, datasource[entity])

    return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({data: datasource[entity]});
        }, 300);
      });
}

function getById(id) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
          const entityData = datasource[entity].find(_entityData => _entityData.id === id);
          resolve({data: entityData});
        }, 300);
      });
}

function create(entity, params) {
    const apiEndpoint = baseUrl + pluralize.plural(entity);
    console.log("CREATE CALLED ", apiEndpoint)
    return axios.post(apiEndpoint, params);
}

function update(id, params) {
    return axios.put(`${baseUrl}/${id}`, params);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(id) {
    return axios.delete(`${baseUrl}/${id}`);
}

function getByFilter(entity, filter) {
    const apiEndpoint = baseUrl + pluralize.plural(entity) + '/byFilter';

    return axios.post(apiEndpoint, filter);
}