import { Button, IconButton } from '@mui/material';
import * as MUIIcons from '@mui/icons-material';

export const WIALCPTextButton = ({ title, ...rest }) => {
    return <Button {...rest}>{title}</Button>
}

export const WIALCPTextIconButton = ({ title, icon, ...rest }) => {
    const BtnIcon = MUIIcons[icon.name];

    return <Button
        endIcon={<BtnIcon {...icon} />}
        {...rest}>{title}</Button>
}

export const WIALCPIconButton = ({ icon, ...rest }) => {
    const BtnIcon = MUIIcons[icon.name];

    return <Button {...rest}><BtnIcon {...icon} /></Button>
}

export const WIALCPIconButtonDefault = ({ icon, ...rest }) => {
    const BtnIcon = MUIIcons[icon.name];

    return <IconButton {...rest}><BtnIcon {...icon} /></IconButton>
}