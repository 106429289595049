import { Fragment } from "react";
import { Redirect, Route, Switch } from "react-router-dom"
import AppLayout from './layouts/AppLayout';
import WIALCPDynamicViewBuilder from "./components/utility/viewBuilders/WIALCPDynamicViewBuilder";
import AuthGuard from "./components/auth/AuthGuard";
import GuestGuard from "./components/auth/GuestGuard";
import NotFoundView from "./components/utility/NotFoundView";

export const renderRoutes = (routes = []) => {
    return <Switch>
        {
            routes.map((route, index) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component || Fragment;

                return <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => {
                        //Loop and render inner routes of the current route, if there no inner routes
                        // just render the Component, ex: Login route not contain inner routes, since its just a page                                        

                        return (
                            <Guard>
                                <Layout>
                                    {route.routes ? renderRoutes(route.routes) : <Component {...props} />}
                                </Layout>
                            </Guard>
                        )
                    }}
                />
            })
        }
    </Switch>
}

export const routes = [
    {
        exact: true,
        path: '/',
        component: () => <Redirect to='/app' />
    },
    {
        exact: true,
        path: '/404',
        component: NotFoundView
    },
    {
        exact: true,
        path: '/login',
        guard: GuestGuard,
        component: WIALCPDynamicViewBuilder
    },
    {
        path: '/app',
        layout: AppLayout,
        guard: AuthGuard,
        routes: [
            {
                exact: true,
                path: '/app/:section/uimodel/:uimodelId',
                component: WIALCPDynamicViewBuilder
            },
            {
                exact: true,
                path: '/app/:section/uimodel/:uimodelId/:entity',
                component: WIALCPDynamicViewBuilder
            },
            {
                exact: true,
                path: '/app/:section/uimodel/:uimodelId/:entity/:id',
                component: WIALCPDynamicViewBuilder
            }
        ]
    }
]

export default routes;