import vendor from './vendor.json';
import user from './users.json';
import role from './roles.json';
import permissions from './permissions.json';
import UIModels from './UIModels';
import countries from './countries.json'

const DataSource = {
    vendor,
    user,
    role,
    permissions,
    UIModels,
    countries
}

export default DataSource;