import React, {
    useRef,
    useState
  } from 'react';
  import { useHistory } from 'react-router-dom';
  import useAuth from "../../../hooks/useAuth";
  
  import {
    Avatar,
    Box,
    ButtonBase,
    Hidden,
    Menu,
    MenuItem,
    Typography,
    makeStyles
  } from '@material-ui/core';
  
  const useStyles = makeStyles((theme) => ({
    avatar: {
      height: 32,
      width: 32,
      marginRight: theme.spacing(1)
    },
    popover: {
      width: 200
    }
  }));
  
  const Account = () => {
    const classes = useStyles();
    const ref = useRef(null);
    const history = useHistory();
    const { user, logout } = useAuth();
  
    const [isOpen, setOpen] = useState(false);
  
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleLogout = async () => {
      try {
        handleClose();
        await logout();
        history.push('/app')
      } catch (err) {
        console.error(err);
      }
    };
  
    return (
      <>
        <Box
          display="flex"
          alignItems="center"
          component={ButtonBase}
          onClick={handleOpen}
          ref={ref}
        >
          <Avatar
            alt="User"
            className={classes.avatar}
            src={""}
          />
          <Hidden smDown>
            <Typography
              variant="body1"
              color="inherit"
            >
              {user && (user.username || (user.email && user.email.split('@')[0]))}
            </Typography>
          </Hidden>
        </Box>
        <Menu
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          keepMounted
          PaperProps={{ className: classes.popover }}
          getContentAnchorEl={null}
          anchorEl={ref.current}
          open={isOpen}
        >
          <MenuItem onClick={handleLogout}>
            Logout
          </MenuItem>
        </Menu>
      </>
    );
  }
  
  export default Account;
  