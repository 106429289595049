import { useEffect } from "react";
import { useReducer } from "react";
import { createContext } from "react";
import { Auth0Client } from '@auth0/auth0-spa-js';
import SplashScreen from "../components/utility/SplashScreen";
import axios from '../utils/axios';

let auth0Client = null;

const initialAuthState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null
};

const AuthContext = createContext({
    ...initialAuthState,
    method: 'Auth0',
    loginWithPopup: () => Promise.resolve(),
    logout: () => {}
});

const setSession = (accessToken) => {
	if (accessToken) {
		localStorage.setItem('accessToken', accessToken);
		axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
	} else {
		localStorage.removeItem('accessToken');
		delete axios.defaults.headers.common.Authorization;
	}
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'INITIALISE': {
            const { isAuthenticated, user } = action.payload;

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user
            }
        }
        case 'LOGIN': {
            const { isAuthenticated, user } = action.payload;

            return {
                ...state,
                isAuthenticated: true,
                user
            }
        }
        case 'LOGOUT': {
            const { isAuthenticated, user } = action.payload;

            return {
                ...state,
                isAuthenticated: false,
                user: null
            }
        }
        default:
            return { ...state }
    }
}

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);

    useEffect(() => {
        const initialise = async () => {
            try {
                auth0Client = new Auth0Client({
                    redirect_uri: window.location.origin,
                    client_id: "3WGlGKPR9DBMOUBBsyIYneKFGTjIBCfJ",
                    domain: "dev-zjcb3u06.us.auth0.com",
                    audience: "https://lowcode/api/v1",
                    scope: "openid profile email"
                });
    
                await auth0Client.checkSession();
    
                const isAuthenticated = await auth0Client.isAuthenticated();

                if (isAuthenticated) {
                    const user = await auth0Client.getUser();
    
                    dispatch({
                        type: 'INITIALISE',
                        payload: {
                            isAuthenticated,
                            user
                        }
                    });
                } else {
                    dispatch({
                        type: 'INITIALISE',
                        payload: {
                            isAuthenticated: false,
                            user: null
                        }
                    })
                }
            } catch (err) {
                dispatch({
                    type: 'INITIALISE',
                    payload: {
                        isAuthenticated: false,
                        user: null
                    }
                })
            }
        };

        initialise();
    }, []);

    const loginWithPopup = async (options) => {
        await auth0Client.loginWithPopup(options);

        const isAuthenticated = await auth0Client.isAuthenticated();

        if (isAuthenticated) {
            const user = await auth0Client.getUser();
            const token = await auth0Client.getTokenSilently();
            
            setSession(token);

            dispatch({
                type: 'LOGIN',
                payload: {
                    isAuthenticated,
                    user
                }
            });
        }
    }

    const logout = () => {
        auth0Client.logout();

        dispatch({ type: 'LOGOUT' });
    }

    if (!state.isInitialised) return <SplashScreen />;

    return <AuthContext.Provider
        value={{ 
            ...state,
            method: 'Auth0',
            loginWithPopup,
            logout
         }}
    >
        {children}
    </AuthContext.Provider>
}

export default AuthContext;

