import React from 'react';
import {
	CssBaseline,
	Grid,
	Box,
	Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '100vh',
		backgroundColor: '#ffffff'
	},
	paperLeft: {
		padding: theme.spacing(10, 8),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		minHeight: "100vh"
	},
	paperCenter: {
		padding: theme.spacing(0, 6),
		display: "flex",
		flexDirection: "column",
		justifyContent: "center"
	},
	paperRight: {
		padding: theme.spacing(10, 8),
		minHeight: '100vh',
		backgroundColor: theme.palette.type === 'light' ? '#e6e5ff' : '#e7e7e7',
	},
	image: {
		backgroundRepeat: 'no-repeat',
		backgroundColor:
			theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	}
}));

export default function SignInExpandRight({ data = {}, children }) {
	const classes = useStyles();
	const largerScreen = useMediaQuery(theme => theme.breakpoints.up('sm') || theme.breakpoints.up('xs'));
	const { layout = {} } = data;
	
	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid item xs={false} sm={false} md={3} lg={3}>
				{largerScreen && <div className={classes.paperLeft}>
					{layout.logo && <div>
						<img
							alt="Logo"
							src={layout.logo}
							style={{ width: '45%' }}
						/>
					</div>}
				</div>
				}
			</Grid>
			<Grid item xs={false} sm={false} md={4} lg={4} className={classes.paperCenter}>
				<div>
					<Box
						alignItems="center"
						display="flex"
						justifyContent="center"
						flexDirection="column"
						mb={3}
					>
						<div>
							<Typography
								color="textPrimary"
								gutterBottom
								variant="h1"
								align="center"
							>
								Hi, Welcome Back
							</Typography>
							<Typography
								variant="h4"
								color="textSecondary"
								align="center"
								gutterBottom
								style={{ fontWeight: 400, marginBottom: 15 }}
							>
								Enter your credentials to continue
							</Typography>
						</div>
					</Box>
					<Box
						flexGrow={1}
						mt={3}
					>
						{children}
					</Box>
				</div>
			</Grid>
			<Grid item xs={false} sm={false} md={5} lg={5}>
				{largerScreen && <div className={classes.paperRight}>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						justifyContent="space-evenly"
						style={{ height: "75vh" }}
					>
						<div>
							<Typography
								color="textPrimary"
								gutterBottom
								variant="h1"
								align="center"
								style={{ fontSize: 40 }}
							>
								WIA Systems Portal
							</Typography>
							<Typography
								variant="h4"
								color="textSecondary"
								align="center"
								gutterBottom
								style={{ fontWeight: 400, marginBottom: 15 }}
							>
								Internal Platform
							</Typography>
						</div>
						<img
							alt="Login"
							src={layout.backgroundImage}
							style={{ width: '80%' }}
						/>
					</Box>
				</div>}

			</Grid>
		</Grid>
	);
}