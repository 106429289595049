import React from "react";
import { Box, Typography, styled, Stack } from "@mui/material";
import Components from '../../components';
import actions from '../../apis/clientActionHandler';
import { useDispatch, useSelector } from '../../redux/store';
import { useHistory } from 'react-router-dom';

const Root = styled('div')(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexShrink: 0,
    minHeight: 50,
    padding: theme.spacing(1)
}))

const FormHeader = ({ UIMetaData }) => {
    const { id: formName, layout } = UIMetaData;
    const { title, subtitle, buttons } = layout && layout.header;
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const history = useHistory();

    const handleButtonClick = btnOnClickEvent => {
        btnOnClickEvent.actions.forEach(action => {
            actions[action](dispatch, state, history)({ formName });
        });
    }

    return (
        <Root>
            {title && <Typography
                color="textPrimary"
                component="div"
                variant="h6"
            >{title}
                {subtitle && <Typography
                    color="GrayText"
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                >{subtitle}</Typography>}
            </Typography>}

            <Box flexGrow={1} />
            <Stack direction="row" spacing={1} alignItems="flex-start">
                {buttons && buttons.length > 0 && buttons.map(btn => {
                    const WIAButton = Components[btn.type];
                    const btnOnClickEvent = btn.events && btn.events.onClick && btn.events.onClick;
                    return <WIAButton {...btn} onClick={btnOnClickEvent ? () => handleButtonClick(btnOnClickEvent) : () => console.log("CLICKED")} />
                })}
            </Stack>
        </Root>
    )
}

export default FormHeader;