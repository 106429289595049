import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      padding: theme.spacing(1),
    }
  },
  typography: {
    marginBottom: theme.spacing(2),
    fontSize: 15
  }
}));

export function WIALCPPanel(props) {
  const classes = useStyles();

  const { title } = props;
  return (
    <React.Fragment>
      <Paper className={classes.paper} variant="outlined" square>
        <Typography component="h1" variant="h6" align="left" className={classes.typography}>
          {title}
        </Typography>
        <React.Fragment>
          {props.children}
        </React.Fragment>
      </Paper>
    </React.Fragment>
  );
}