import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Settings from '@mui/icons-material/Settings';
import * as MUIIcons from '@mui/icons-material';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function NavBar({ open, navItems = [], defaultSettingsPath = { href: '/app/settings' }, onDrawerOpenClose, onSettingsNavBarOpen }) {
  const history = useHistory();

  const handleDrawerOpenClose = () => {
    onDrawerOpenClose();
  };

  const handleSettingsClick = () => {
    history.push(defaultSettingsPath.href)
    onSettingsNavBarOpen();
  }

  return (
    <Drawer variant="permanent" open={open}>
      <List style={{ height: window.innerHeight - 100, marginTop: 55 }}>
        {navItems.filter(item => !item.hideInNavBar).map(item => {
          const NavIcon = MUIIcons[item.icon];
          return (
            <ListItem
              selected={window.location.pathname === item.href}
              button 
              key={item.title} 
              component={RouterLink} 
              to={item.href}>
              <ListItemIcon>
                <NavIcon />
              </ListItemIcon>
              <ListItemText secondary={item.title} />
            </ListItem>
          )
        })}
      </List>
      <Divider />
      <List>
        <ListItem button key={"Settings"} onClick={handleSettingsClick}>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText secondary={"Settings"} />
        </ListItem>
      </List>
      <Divider />
      <DrawerHeader>
        <IconButton
          onClick={handleDrawerOpenClose}
        >
          {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
    </Drawer>
  );
}
