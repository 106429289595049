import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import actions from '../../apis/clientActionHandler';
import { useDispatch } from '../../redux/store';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import Components from '../../components';

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export default function NavTabs({ children, ...rest }) {
  const { layout = {} } = rest.data || {};
  const { navItems = [], buttons = [], addData = {} } = layout;
  const { formName, showType, type } = addData;

  const [value, setValue] = useState(navItems[0]);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.replace(newValue.href)
  };

  const handleButtonClick = btnOnClickEvent => {
    btnOnClickEvent.actions.forEach(action => {
        actions[action](dispatch)({formName, showType, type});
    });
}

  return (
    <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
      {buttons.length > 0 &&
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          {buttons.map(btn => {
            const WIAButton = Components[btn.type];
            const btnOnClickEvent = btn.events && btn.events.onClick && btn.events.onClick;
            return <WIAButton {...btn} onClick={btnOnClickEvent ? () => handleButtonClick(btnOnClickEvent) : () => console.log("CLICKED")}/>
          })}
        </Stack>
      }
      <Tabs value={value} onChange={handleChange} aria-label="nav tabs">
        {navItems.map(item => {
          return <LinkTab key={item.href} value={item} label={item.label} href={item.href} />
        })}
      </Tabs>
      <div style={{ marginTop: 15 }} />
      {children}
    </Box>
  );
}