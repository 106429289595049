import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import routes, { renderRoutes } from './routes';
import { AuthProvider } from './contexts/Auth0Context';

const theme = createTheme({
  palette: {
    primary: {
      main: "#00CCA7",
      text: "#595959"
    },
    error: {
      main: "#e0323e"
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <BrowserRouter>
          {renderRoutes(routes)}
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
