import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button } from '@mui/material';
import FilterOption from './FilterOption';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        border: "1px solid #0000001f",
        padding: theme.spacing(2),
    }
}))

export default function Filter({ UIMetaData = {} }) {
    const classes = useStyles();
    const { filters = [] } = UIMetaData;

    return (
        <div className={classes.mainContainer}>
            <Grid container spacing={1} direction="column">
                <Grid item>
                    <Typography variant="h6" style={{ borderBottom: '1px solid #0000001f' }}>
                        Filters
                    </Typography>
                </Grid>
                {filters.map(option => <FilterOption FilterOption={option} />)}
                <Grid item>
                    <Button fullWidth disableElevation variant='contained' style={{ color: '#ffffff'}}>Apply Filter</Button>
                </Grid>
            </Grid>
        </div>
    )
}