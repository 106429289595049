import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import TopBar from './topbar';
import NavBar from './NavBar';
import NavBarSettings from './NavBarSettings';

import useAuth from '../../hooks/useAuth';

import getUIModelById from '../../apis/clientUIModelHandler';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    marginTop: theme.spacing(7)
  },
}));

export default function AppLayout({ children }) {
  const classes = useStyles();
  const { user } = useAuth();
  const [navItemsMain, setNavItemsMainMeta] = useState([]);
  const [navItemsSettings, setNavItemsSettingsMeta] = useState([]);

  const [open, setOpen] = React.useState(true);
  const [openSettingsNavBar, setOpenSettingsNavBar] = React.useState(false);

  useEffect(() => {
    //Prevent display main navbar after page refresh when user in settings page
    if (window.location.pathname.includes("settings")) {
      setOpenSettingsNavBar(true);
    }

    const fetchNavMainItemsData = async () => {
      const navItemsMetadata = await getUIModelById("navItemsMain");
      setNavItemsMainMeta(navItemsMetadata.source);
    }

    const fetchNavSettingsItemsData = async () => {
      const navItemsMetadata = await getUIModelById("navItemsSettings");
      setNavItemsSettingsMeta(navItemsMetadata.source);
    }

    fetchNavMainItemsData();
    fetchNavSettingsItemsData();
  }, [])

  const handleDrawerOpenClose = () => {
    setOpen(!open);
  };

  const handleSettingsNavBarOpen = () => {
    setOpenSettingsNavBar(true);
  };

  const handleSettingsNavBarClose = () => {
    setOpenSettingsNavBar(false);
  };

  const filterAuthorizedNavItems = (navItems = []) => {
    return navItems.filter(item => {
      if (item.roles.includes("*")) return item;

      const hasPermission = item.roles.find(_role => user && user.roles && user.roles.find(_userRole => _userRole.role === _role));
    
      if (hasPermission) return item;
    });
  }

  return (
    <div className={classes.root}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <TopBar />
        {!openSettingsNavBar && 
          <NavBar 
            open={open}
            //Comment bellow line to remove the authorization for navbar. this need to uncomment
            //after implement the authentication feature
            // navItems={filterAuthorizedNavItems(navItemsMain)}
            navItems={navItemsMain}
            defaultSettingsPath={filterAuthorizedNavItems(navItemsSettings)[0]}
            onDrawerOpenClose={handleDrawerOpenClose}
            onSettingsNavBarOpen={handleSettingsNavBarOpen}/>}
        {openSettingsNavBar && 
          <NavBarSettings 
            open={open}
            navItems={filterAuthorizedNavItems(navItemsSettings)}
            onDrawerOpenClose={handleDrawerOpenClose}
            onSettingsNavBarClose={handleSettingsNavBarClose}
          />}
      </Box>
      <main className={classes.content}>
        {/* <ContentHeader /> */}
        {children}
      </main>
    </div>
  );
}
