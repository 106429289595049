import { cloneElement } from 'react';
import useAuth from '../../hooks/useAuth';

let USER_ROLES = [];
let ROLE_PERMISSIONS = [];

export default function PermissionGate({
    children,
    RenderError = () => <></>,
    permissionSet,
    visibleCondition = null,
    readOnlyCondition = null,
}) {
    const { user } = useAuth();
    USER_ROLES = user && user.roles || [];

    //Temprorry Comment bellow line to remove the authorization. this need to uncomment
    //after implement the authentication feature

    // if (!permissionSet) return <>{children}</>

    // setAllowedPermissionsFromPermissionSet(permissionSet)

    // if (permissionSet && !ROLE_PERMISSIONS.includes("View")) return <p>You're not authorised to view this page</p>;

    // const allowVisibility = visibleCondition !== null ? eval(visibleCondition) : true;
    // const readyOnly = readOnlyCondition !== null ? eval(readOnlyCondition) : false;

    // if (permissionSet && !allowVisibility) return <RenderError />;

    // if (permissionSet && allowVisibility && readyOnly) return cloneElement(children, { disabled: true })

    return <>{children}</>
}

const setAllowedPermissionsFromPermissionSet = permissionSet => {
    USER_ROLES.length > 0 && USER_ROLES
        .forEach(_role => _role.permissions
            .forEach(_rolePermission => {
                if (_rolePermission.permissionSet === permissionSet.permissionSetCode) {
                    ROLE_PERMISSIONS = [...ROLE_PERMISSIONS, ..._rolePermission.allowedPermissions]
                }

            }));

    ROLE_PERMISSIONS = [...new Set([...ROLE_PERMISSIONS])];
}

const checkPermission = permission => {
    return ROLE_PERMISSIONS.includes(permission);
};

const checkRole = role => {
    if (role === "*") return true;

    return USER_ROLES.find(_userRole => _userRole.role === role);
};