import { Select, FormControl, Stack } from '@mui/material';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Components from '../../components';
import actions from '../../apis/clientActionHandler';
import { useDispatch, useSelector } from '../../redux/store';
import { useHistory } from 'react-router-dom';
import PermissionGate from '../../components/auth/PermissionGate';

const useStyles = makeStyles((theme) => ({
    iconButton: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}))

export default function CardListFilter({ UIMetaData = {}, permissionSet }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const routerHistory = useHistory()
    const { layout = {} } = UIMetaData;
    const { addData = {}, recordListActionBar = {} } = layout;
    const { statusFilter, buttons = [] } = recordListActionBar;
    const { formName, showType, type } = addData;

    const handleButtonClick = btnOnClickEvent => {
        btnOnClickEvent.actions.forEach(action => {
            actions[action](dispatch, state, routerHistory)({ formName, showType, type, parameters: { onClick: btnOnClickEvent } });
        });
    }

    return (
        <div>
            <Stack spacing={0.2} direction="row" justifyContent="space-between" alignItems="center">
                {statusFilter && <FormControl variant="outlined" size={"small"} style={{ width: '100%' }}>
                    <Select native placeholder="Options">
                        {statusFilter.options
                            && statusFilter.options.map(option => <option value={10}>{option}</option>)
                        }
                    </Select>
                </FormControl>}
                {buttons.map(btn => {
                    const WIAButton = Components[btn.type];
                    const btnOnClickEvent = btn.events && btn.events.onClick && btn.events.onClick;
                    return (
                        <PermissionGate
                            permissionSet={permissionSet}
                            visibleCondition={btn.visibleCondition}
                            readOnlyCondition={btn.readOnlyCondition}>
                            <WIAButton
                                {...btn}
                                onClick={
                                    btnOnClickEvent ?
                                        () => handleButtonClick(btnOnClickEvent) :
                                        () => console.log("CLICKED")
                                }
                            />
                        </PermissionGate>
                    )
                })}
            </Stack>
        </div>
    )
}