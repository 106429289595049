import { openModal, closeModal } from '../redux/slices/modals';
import { entityAPIService } from './serverActionHandler';
import UIModels from '../helpers/datasource/UIModels';

const useGridCreate = (dispatch, state, routerHistory) => ({ formName, showType, parameters = {} }) => {
    const defaultClick = () => console.log("DEFAULT ACTION")

    if (showType) {
        if (showType === "modal") {
            dispatch(
                openModal({ window: { content: formName, showType } })
            )
        } else {
            //TODO: Need to implement sidebar window open functionality
            defaultClick();
        }
    } else {
        //TODO: Need to implement Default behaviour. Default behaviour is redirect to page
        routerHistory.push(parameters.onClick.parameters[0].value)
    }
    
    return {}
}

const useExit = (dispatch, state, routerHistory) => ({ formName }) => {
    const { windows = {} } = state.modals;
    const windowList = Object.values(windows);
    const formOpenModal = windowList.length > 0 && windowList.find(win => win.content === formName);

    if (formOpenModal) {
        dispatch(
            closeModal({ window: formOpenModal })
        )
    } else {
        routerHistory.goBack();
    }

    return {}
}

const useNavigate = (dispatch, state, routerHistory) => ({ data, parameters = {} }) => {
    let pathTemplate = parameters.onClick.parameters[0].value;
    
    pathTemplate = pathTemplate.replace(/{([^{}]+)}/g, function(keyExpr, key) {
        let dataValue = data;

        if (key.includes(".")) { 
            key.split(".").forEach(path => {
                dataValue = dataValue[path]
            });
    
            return dataValue || "";
        }
        return data[key] || "";
    });

    routerHistory.push(pathTemplate)
    return {}
}

export const saveData = (dispatch, state, routerHistory) => async ({ formName, data }) => {
    const UIModel = UIModels[formName];

    //UI model notfind display error to gloable state;
    await entityAPIService
            .create(UIModel.entity, data)
            .then(res => res)
            .catch(err => console.log("ERR ", err))
}

export default {
    gridCreate: useGridCreate,

    saveData,
    exit: useExit,
    navigate: useNavigate,
}