import React, { useState } from 'react'
import { TabContext, TabPanel, TabList, } from '@mui/lab';
import { Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    tabHeader: {
        border: "1px solid #0000001f",
        marginLeft: -16,
        marginRight: -16,
        borderLeft: "none",
        borderRight: "none"
    }
}))

export default function DetailViewTabSection({ UIMetaData }) {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState("1");
    const { navItems = [] } = UIMetaData;
    console.log("NAV ITMS ", UIMetaData)
    return (
        <TabContext value={selectedTab}>
            {navItems.length > 0 && <TabList onChange={(event, value) => setSelectedTab(value)} className={classes.tabHeader} indicatorColor="black">
                {navItems.map((item, idx) => {
                    return <Tab label={item.label} value={idx + 1} />
                })}
            </TabList>
            }
            {navItems.length > 0 && navItems.map((item, idx) => {
                return <TabPanel value={idx + 1}>{item.label}</TabPanel>
            })}
        </TabContext>
    )
}