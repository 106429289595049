import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Components from './index';
import actions from '../apis/clientActionHandler';
import { useDispatch, useSelector } from '../redux/store';

const actionColumn = (buttons, dispatch, state, routerHistory) => {
    const handleButtonClick = (btnOnClickEvent, data) => {
        btnOnClickEvent.actions.forEach(action => {
            actions[action]
                (dispatch, state, routerHistory)
                ({ data, parameters: { onClick: btnOnClickEvent } });
        });
    }

    return {
        field: 'actions',
        headerName: 'Actions',
        flex: 2,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => {
            return (
                <Stack direction="row" spacing={1} alignItems="flex-start">
                    {buttons && buttons.length > 0 && buttons.map(btn => {
                        const WIAButton = Components[btn.type];
                        const btnOnClickEvent = btn.events && btn.events.onClick && btn.events.onClick;
                        return <WIAButton {...btn} onClick={btnOnClickEvent ? () => handleButtonClick(btnOnClickEvent, params.row) : () => console.log("CLICKED")} />
                    })}
                </Stack>
            )
        }
    }
}

export default function List({ data = {}, setSelectionModel, selectionModel }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { apiData = [], columns = [], gridActions = [] } = data;

    const getPreparedTableColumns = () => {
        const tableColumns = [...columns];

        if (gridActions.length > 0) {
            const gridActionColumn = actionColumn(gridActions, dispatch, state, history);
            tableColumns.push(gridActionColumn);
        }

        return tableColumns.map(col => col.valueFormatter ?
            ({ ...col, valueFormatter: eval(col.valueFormatter) })
            : col
        );
    }

    console.log("List PROPS ", apiData, columns, gridActions)

    return (
        <div>
            <DataGrid
                getRowId={(row) => row.id}
                rows={apiData}
                columns={getPreparedTableColumns()}
                pageSize={10}
                checkboxSelection
                disableSelectionOnClick
                autoHeight
                autoPageSize
                disableColumnFilter
                disableColumnMenu
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
            />
        </div>
    )
}