import React, { useState } from 'react';
import {
  Box,
  Button,
  FormHelperText,
  makeStyles
} from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Auth0Login = () => {
  const { loginWithPopup } = useAuth();
  const [error, setError] = useState(null);
  const isMountedRef = useIsMountedRef();

  const handleLogin = async () => {
    try {
      await loginWithPopup();
    } catch (err) {
      console.error("LOG WITH POPUP: ", err);
      if (isMountedRef.current) {
        setError(err.message);
      }
    }
  };

  return (
    <div>
      {error && (
        <Box my={3}>
          <FormHelperText error>
            {error}
          </FormHelperText>
        </Box>
      )}
      <Box
        display="flex"
        justifyContent="center"
      >
        <Button
          onClick={handleLogin}
          variant="contained"
          color="secondary"
        >
          Log in with Auth0
        </Button>
      </Box>
    </div>
  );
};

export default Auth0Login;
