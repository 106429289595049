import * as Yup from 'yup';

export const buildURLQuery = obj =>
	Object.entries(obj)
		.map(pair => pair.map(encodeURIComponent).join('='))
		.join('&');

export const getUrlMap = url => {
	const urlParts = (url ? url + '' : window.location.pathname).split('/').filter(part => part !== "");

	return {
		root: urlParts.length > 0 ? urlParts[0] : null,
		section: urlParts.length > 1 ? urlParts[1] : null,
		uimodelId: urlParts.length > 3 ? urlParts[3] : null,
	}
}

export const wait = (time) => {
	return new Promise((res) => setTimeout(res, time));
};

export function prepModel(model) {
	console.log("prepModel ", model);
	if (model) {
		if (!model._prepared) {
			model.validationSchema = getValidationSchema(model.components);
			model.fieldsH = hashById(model.components);

			const modelMetadata = getModelMetaData(model.components);
			model.dataModel = modelMetadata.dataModel;
			model.subFormNamespaces = modelMetadata.namespaces;

			model._prepared = true
		}
		return model;
	}
	return null;
}

export function hashById(objects, prop = 'id') {
	var map = {};
	if (objects) {
		objects.forEach(function (object) {
			map[object[prop]] = { ...object, name: object.namespace ? `${object.namespace}.${object.name}` : object.name };
		});
	}
	return map;
}

export function getValidationSchema(objects = []) {
	var vschema = {};

	objects.length > 0 && objects.forEach(_object => {
		const validation = _object.validation;

		validation && Object.keys(_object.validation).forEach(_validationKey => {
			if (_object.namespace) {
				const subformVSchema = vschema[_object.namespace] || {};
				setValidationSchema(subformVSchema, _object, _validationKey);
				vschema[_object.namespace] = subformVSchema;
			} else {
				setValidationSchema(vschema, _object, _validationKey)
			}	
		})
	});

	return vschema;
}

function setValidationSchema(vschema, _object, _validationKey) {
	const schema = Yup[_object.dataType || 'string']()[_validationKey](_object.validation[_validationKey]);

	if (vschema[_object.id]) {
		vschema[_object.id] = vschema[_object.id].concat(schema)
	} else {
		vschema[_object.id] = schema;
	}
}

export function getModelMetaData(objects, prop = 'id') {
	var dataModel = {};
	var namespaces = [];
	if (objects) {
		objects.forEach(function (object) {
			if (object.namespace) {
				const subformModel = dataModel[object.namespace] || {};
				setDataModel(subformModel, object, prop);
				dataModel[object.namespace] = subformModel;

				let namespaceExist = namespaces.find(_namespace => _namespace === object.namespace);
				!namespaceExist && namespaces.push(object.namespace);
			} else {
				setDataModel(dataModel, object, prop);
			}			
		});
	}
	return { dataModel, namespaces };
}

function setDataModel(map, object, prop) {
	map[object[prop]] = typeof object.defaultValue !== Function ? object.defaultValue : eval(object.defaultValue);
}

export const fieldId2Field = (fieldIds, fieldsH) => fieldIds ? fieldIds.map(id => fieldsH[id] || null) : null;
