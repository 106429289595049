import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Select, FormControl, Stack, Button } from '@mui/material';
import Components from '../../components';
import { MdRefresh } from "react-icons/md";
import actions from '../../apis/clientActionHandler';
import { useDispatch, useSelector } from '../../redux/store';
import { useHistory } from 'react-router-dom';
import PermissionGate from '../../components/auth/PermissionGate';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        border: "1px solid #0000001f",
        padding: theme.spacing(2),
    },
    IconButton: {
        textTransform: 'none',
        margin: "0px 5px"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}))

export default function ActionBar({ UIMetaData = {}, permissionSet }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const routerHistory = useHistory()
    const { actionBar = {}, addData = {} } = UIMetaData;
    const { statusFilter, pageRefreshBtn, buttons = [] } = actionBar;
    const { formName, showType, type } = addData;

    const handleButtonClick = btnOnClickEvent => {
        btnOnClickEvent.actions.forEach(action => {
            actions[action](dispatch, state, routerHistory)({ formName, showType, type, parameters: { onClick: btnOnClickEvent } });
        });
    }

    return (
        <div className={classes.mainContainer}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                {statusFilter && <Grid item lg={2}>
                    <FormControl variant="outlined" fullWidth size={"small"} className={classes.formControl}>
                        <Select
                            native
                            inputProps={{
                                name: 'age',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            {statusFilter.options
                                && statusFilter.options.map(option => <option value={10}>{option}</option>)
                            }
                        </Select>
                    </FormControl>
                </Grid>}
                <Grid item lg={1}>
                    {pageRefreshBtn &&
                        <Button
                            className={classes.IconButton}
                            fullWidth
                            size="small"
                            variant="outlined">
                            <MdRefresh size="18" />
                        </Button>}
                </Grid>
                <Grid container lg={9} alignItems="center" justifyContent="flex-end">
                    {buttons.length > 0 &&
                        <Stack spacing={1} direction="row" justifyContent="flex-end">
                            {buttons.map(btn => {
                                const WIAButton = Components[btn.type];
                                const btnOnClickEvent = btn.events && btn.events.onClick && btn.events.onClick;
                                return (
                                    <PermissionGate
                                        permissionSet={permissionSet}
                                        visibleCondition={btn.visibleCondition}
                                        readOnlyCondition={btn.readOnlyCondition}>
                                        <WIAButton
                                            {...btn}
                                            onClick={
                                                btnOnClickEvent ?
                                                    () => handleButtonClick(btnOnClickEvent) :
                                                    () => console.log("CLICKED")
                                            }
                                        />
                                    </PermissionGate>
                                )
                            })}
                        </Stack>
                    }
                </Grid>
            </Grid>
        </div>
    )
}