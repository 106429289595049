import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Select, FormControl, Stack, Button } from '@mui/material';
import Components from '../../components';
import { MdRefresh } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        border: "1px solid #0000001f",
        padding: theme.spacing(2),
    },
    IconButton: {
        textTransform: 'none',
        margin: "0px 5px"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}))

export default function ActionBar({ UIMetaData = {}, onCreateClick }) {
    const classes = useStyles();
    const { buttons } = UIMetaData;

    return (
        <div className={classes.mainContainer}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid container lg={3}>
                    <Stack spacing={1} direction="row" justifyContent="flex-start">
                        <Button disabled>N - Selected</Button>
                        <Button>Clear</Button>
                    </Stack>
                </Grid>
                <Grid container lg={7} alignItems="center">
                    {buttons.length > 0 &&
                        <Stack spacing={1} direction="row" justifyContent="flex-end">
                            {buttons.map(btn => {
                                const WIAButton = Components[btn.type];
                                return <WIAButton {...btn} onClick={btn.icon && btn.icon.name.includes("AddCircleOutline") ? onCreateClick : () => console.log("CLICKED")} />
                            })}
                        </Stack>
                    }
                </Grid>
                <Grid container lg={2} justifyContent="flex-end">
                    <Button>{"<         >"}</Button>
                </Grid>
            </Grid>
        </div>
    )
}