import React from 'react';
import { useField } from 'formik';
import {
    TextField,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    FormHelperText,
    Checkbox,
    ListItemText,
    Box,
    Chip
} from "@mui/material";
import { LocalizationProvider, DesktopDatePicker } from "@mui/lab";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import datasource from '../../helpers/datasource';

export const WIATextFieldComponent = (props) => {
    const [field, meta] = useField(props.name);
    
    return <TextField
        {...props}
        {...field}
        error={meta.touched && meta.error}
        helperText={meta.touched && meta.error}
        size="small"
        variant="outlined"
        fullWidth />
};

export const WIANumberFieldComponent = (props) => {
    const [field, meta] = useField(props.name);

    return <TextField
        {...props}
        {...field}
        error={meta.touched && meta.error}
        helperText={meta.touched && meta.error}
        type="number"
        size="small"
        variant="outlined"
        fullWidth />
};

export const WIAPasswordFieldComponent = (props) => {
    const [field, meta] = useField(props.name);

    return <TextField
        {...props}
        {...field}
        error={meta.touched && meta.error}
        helperText={meta.touched && meta.error}
        type="password"
        size="small"
        variant="outlined"
        fullWidth
    />
};

export const WIATextMultilineFieldComponent = (props) => {
    const [field, meta] = useField(props.name);

    return <TextField
        {...props}
        {...field}
        error={meta.touched && meta.error}
        helperText={meta.touched && meta.error}
        multiline
        fullWidth />
}

export const WIASelectFieldComponent = (props) => {
    const [field, meta] = useField(props.name);
    const { options, optionReference, label, ...rest } = props;

    let selectOptions = [];
    if (options) {
        selectOptions = options.map(item => createTextOption(item.value, item.label));
    } else {
        if (optionReference && datasource[optionReference]) {
            selectOptions = datasource[optionReference].map(item => createTextOption(item.value, item.label));
        }
    }

    return <FormControl variant="outlined" style={{ width: '100%' }} size="small">
        <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
        <Select
            {...rest}
            {...field}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label={label}
        >
            {selectOptions}
        </Select>
        <FormHelperText>{meta.touched && meta.error}</FormHelperText>
    </FormControl>
};

function createTextOption(id, text) {
    return <MenuItem key={id} value={'' + id}>{text}</MenuItem >
}

export function WIAMultiSelectCheckmarksComponent(props) {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const [field, meta] = useField(props.name);
    const { id, options, label, setFieldValue, ...rest } = props;
    const selectedValues = field.value;

    const handleChange = (event) => {
        const { target: { value } } = event;

        const isOptionExist = selectedValues.find(_selectedValue => _selectedValue.value === value.value);
        let newSelections = [];
        if (isOptionExist)
            newSelections = selectedValues.filter(_selectedValue => _selectedValue.value !== value.value);
        else
            newSelections = [...selectedValues, value];

        setFieldValue(id, newSelections)
    };

    return (
        <div>
            <FormControl variant="outlined" style={{ width: '100%' }} size="small">
                <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
                <Select
                    {...rest}
                    {...field}
                    labelId="demo-multiple-checkbox-label"
                    label={label}
                    id="demo-multiple-checkbox"
                    value={selectedValues}
                    onChange={handleChange}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((_selected) => (
                                <Chip key={_selected.value} label={_selected.label} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                    fullWidth
                >
                    {options ? options.map(item => createCheckboxOption(item, selectedValues)) : []}
                </Select>
                <FormHelperText>{meta.touched && meta.error}</FormHelperText>
            </FormControl>
        </div>
    );
}

function createCheckboxOption(option, selectedOptions) {
    return <MenuItem key={option.value} value={option}>
        <Checkbox checked={selectedOptions.find(_selectedOption => _selectedOption.value === option.value)} />
        <ListItemText primary={option.label} />
    </MenuItem >
}

export const WIADatePicker = props => {
    const [field, meta] = useField(props.name);

    return (
        <FormControl variant="outlined" style={{ width: '100%' }} size="small">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    {...props}
                    {...field}
                    onChange={value => props.setFieldValue(props.id, value)}
                    renderInput={(params) => <TextField {...params} fullWidth size="small" />}
                />
            </LocalizationProvider>
            <FormHelperText>{meta.touched && meta.error}</FormHelperText>
        </FormControl>
    )
}
