import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardContainer: {
  },
  cardContent: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400
  }
}));

const SignInCenter = ({ data = {}, children }) => {
  const classes = useStyles();
  const { layout = {} } = data;

  return (
    <div
      className={classes.root}
      style={{
        backgroundImage: `url(${layout.backgroundImage})`,
        backgroundColor: layout.backgroundColor
      }}
    >
      <Container
        className={classes.cardContainer}
        maxWidth="sm"
      >
        <Card>
          <CardContent className={classes.cardContent}>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              mb={3}
            >
              <div>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h4"
                >
                  SIGN IN
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                >
                  Sign in on the internal platform
                </Typography>
              </div>
              {layout.logo && <div>
                  <img
                    alt="Logo"
                    src={layout.logo}
                    style={{ height: 40 }}
                  />
                </div>}
            </Box>
            <Box
              flexGrow={1}
              mt={3}
            >
              {children}
            </Box>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default SignInCenter;
