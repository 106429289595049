import { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Layouts from '../../../layouts';
import WIALCAPTable from '../../WIALCAPTable';
import PermissionGate from '../../auth/PermissionGate';
import { entityAPIService } from '../../../apis/serverActionHandler';
var pluralize = require('pluralize')

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%'
  }
}));

//The purpose of this component is to build the list view.
export default function ListBuilder({ data = {}, permissionSet }) {
  console.log("ListBuilder ", data);

  const classes = useStyles();
  const [apiData, setApiData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  //Find the list layout from our predefined layouts
  const Layout = (data.layout && data.layout.id) ? Layouts[data.layout.id] : Fragment;
  const { components = [], ...rest } = data;
  const listComponentData = components.length > 0 && components[0];

  useEffect(() => {
    const fetchData = async () => {
      await entityAPIService
        .getAll(data.entity)
        .then(res => {
          const entityData = res.data
          setApiData(entityData)
        })
        .catch(err => console.log("ERROR ", err))
    }

    setApiData([])
    fetchData();
  }, [data && data.entity]);

  console.log("API DATA ", apiData)
  return (
    <div className={classes.root}>
      <PermissionGate permissionSet={permissionSet}>
        <Layout UIMetaData={rest} selectionModel={selectionModel} permissionSet={permissionSet}>
          <WIALCAPTable
            data={{ ...listComponentData, apiData }}
            setSelectionModel={setSelectionModel}
            selectionModel={selectionModel}
            permissionSet={permissionSet} />
        </Layout>
      </PermissionGate>
    </div>
  )
}