import { Dialog } from '@mui/material';
import { useSelector } from '../redux/store';
import WIALCPFormBuilder from './utility/viewBuilders/WIALCPFormBuilder';
import UIModels from '../helpers/datasource/UIModels';

const WIALCPModal = () => {
    const { windows = {} } = useSelector((state) => state.modals);
    const windowList = Object.values(windows);
    // TODO: Logic need to implement multiple modal open feature. for now only support for
    // one modal at a time.
    const modalContentData = windowList.length > 0 && windowList[0];
    const UIModel = Object.values(UIModels).find(model => model.id === modalContentData.content);
    return (
        <Dialog
            maxWidth="sm"
            open={modalContentData}
        >
            {/* Dialog renders its body even if not open */}
            {modalContentData && (
                <WIALCPFormBuilder data={UIModel} />
            )}
        </Dialog>
    )
}

export default WIALCPModal;