import React from 'react';
import {
  Box,
  Button,
  TextField,
  makeStyles,
  Typography,
  FormHelperText
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles(() => ({
  root: {},
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: "#546e7a",
    marginTop: 5
  }
}));

const JWTLogin = ({ className, ...rest }) => {
  const classes = useStyles();
  const { login } = useAuth();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        password: Yup.string().max(255).required('Password is required')
      })}
      onSubmit={ async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          await login(values.email, values.password);

          setStatus({ success: true });
          setSubmitting(false)
        } catch (error) {
          console.log("ERRROR ", error)
          setStatus({ success: false });
          setSubmitting(false);
          setErrors({ submit: error.message })
        }
       }}
    >
      {({
        values,
        errors,
        isSubmitting,
        touched,
        handleBlur,
        handleChange,
        handleSubmit
      }) => (
        <form
          noValidate
          className={classes.root}
          {...rest}
          onSubmit={handleSubmit}
        >
          <Typography variant="h6" color="textPrimary" align="center" style={{ marginBottom: 20 }}>
            Sign in with email address
          </Typography>
          <TextField
            fullWidth
            autoFocus
            label="Email Address"
            margin="normal"
            name="email"
            type="email"
            variant="outlined"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            name="password"
            type="password"
            variant="outlined"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <Box
            display="flex"
            justifyContent="space-between"
          >
            <a href="/forgotPassword" className={classes.link}>Forgot password?</a>
          </Box>
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              style={{ marginTop: 15 }}
              disabled={isSubmitting}

            >
              Sign In
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default JWTLogin;
