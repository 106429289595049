import { Chip, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { Tag } from '@mui/icons-material';
import { Stack } from '@mui/material';
import React from 'react';

export default function SelectedItemsList({ selectedItems=[] }) {
    const columns = [
        {
            field: 'code', headerName: 'Item Details', flex: 2,
            renderCell: (value) => {
                const data = value.row
                return (
                    <Stack style={{ height: 40 }} justifyContent='center'>
                        <Stack direction='row' spacing={2} alignItems="bottom">
                            <Typography variant='subtitle2'>{data.name}</Typography>
                            <Chip label={data.code} style={{ fontSize: 10, height: 20, borderRadius: 5, fontWeight: 600 }} />
                        </Stack>
                        <Stack>
                            <Typography variant='body2' style={{ fontSize: 12 }}>
                                {data.description.slice(0, 100) + " ..."}
                            </Typography>
                        </Stack>
                    </Stack>
                )
            }
        },
        { field: 'quantity', headerName: 'Quantity', flex: 0.5, type: 'number' },
        { field: 'rate', headerName: 'Rate', flex: 0.5, type: 'number' },
        { field: 'tax', headerName: 'Tax', flex: 0.5, type: 'number' },
        { field: 'amount', headerName: 'Amount', flex: 0.5, type: 'number', renderCell: (value) => {
            const data = value.row;
            const amount = data.quantity * data.rate;
            return(
                <p>{amount.toString()}</p>
            )

        } }
    ]

    return (
        <div style={{ width: '100%' }}>
            <DataGrid
                rows={selectedItems}
                columns={columns}
                checkboxSelection={false}
                rowHeight={60}
                autoHeight
                hideFooterPagination />
        </div>
    )
}