import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import WIALCPPageBuilder from './WIALCPPageBuilder';
import WIALCPListBuilder from './WIALCPListBuilder';
import WIALCPFormBuilder from './WIALCPFormBuilder';
import WIALCPModal from '../../WIALCPModal';
import DataSource from '../../../helpers/datasource';
import { getUrlMap } from "../../../utils/util";
import getUIModelById from '../../../apis/clientUIModelHandler';

const UIModels = DataSource.UIModels;

export default function WIALCPDynamicViewBuilder() {
    const [UIMetaData, setUIMetaData] = useState();

    const urlMap = getUrlMap(window.location.pathname);
    let { uimodelId } = useParams();

    //Login page is static. We don't render them dynamically. 
    const isLogin = urlMap['root'] === 'login';
    if (isLogin) uimodelId = 'login'

    useEffect(()=>{
        const fetchData = async () => {
            const apiData = await getUIModelById(uimodelId);
            setUIMetaData(apiData);
        }
        
        fetchData();
    }, [uimodelId])

    console.log("WIALCPDynamicViewBuilder ", UIMetaData)

    //Get the layout type of the UIMetadata
    const layoutType = UIMetaData && UIMetaData.source ? UIMetaData.source.layout.type : 'error';

    const UIPermissions = UIMetaData && UIMetaData.permissions && getUIPermissions(UIMetaData.permissions.permissionSet);

    
    //Here we find the appropriate view buider to generate the UI by layout type.
    let UIBuilder = null;

    switch (layoutType) {
        case 'page': {
            UIBuilder = WIALCPPageBuilder;
            break;
        }
        case 'report': {
            UIBuilder = WIALCPListBuilder;
            break;
        }
        case 'form': {
            UIBuilder = WIALCPFormBuilder;
            break;
        }
        default: {
            UIBuilder = function defaultUI() { return <h3>Coming Soon...</h3> }
            break;
        }
    }

    return UIMetaData && UIMetaData.source ? <>
        <UIBuilder data={UIMetaData.source} permissionSet={UIPermissions} />
        <WIALCPModal />
    </> : <div>Unavailable</div>
}

const getUIPermissions = permissionSetCode => {
    const permissions = DataSource.permissions || [];

    const uiPermissionSet = permissions.find(permission => permission.permissionSetCode === permissionSetCode);

    return uiPermissionSet;
}