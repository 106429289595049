import axios from '../utils/axios';

let baseUrl = "http://localhost:8091/api/v1/matadata/uimodels/"
let config = {
    headers: {
        'Access-Control-Allow-Origin': '*',
      }
}

const getUIModelById = async (modelId) => {
    const endpoint = baseUrl + "?id=" + modelId;
    
    try {
        const response = await axios.get(endpoint, config);

        if (response.data.status) {
            const apiData = response.data.data;
            
            return { ...apiData, source: JSON.parse(apiData.source)}
        } else {
            return;
        }
    } catch(exception) {
        console.log("ERROR ", exception)
        return;
    }
    
}

export default getUIModelById;