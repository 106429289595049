import { Stack } from '@mui/material';
import React from 'react';
import ListCard from './ListCard';
import PerfectScrollbar from 'react-perfect-scrollbar';

export default function CardList({ UIMetaData, dataList = [] }) {
    return (
        <PerfectScrollbar
            style={{ maxHeight: window.innerHeight - 173 }}
            options={{ suppressScrollY: false }}
        >
            <Stack spacing={1} style={{ marginTop: 10 }}>
                {
                    dataList.map(_data => <ListCard UIMetaData={UIMetaData} details={_data} />)
                }
            </Stack>
        </PerfectScrollbar>

    )
}