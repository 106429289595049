import React from 'react';
import {
  CssBaseline,
  Paper,
  Grid,
  Box,
  Typography,
  Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paperRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: "center",
    minHeight: "100vh"
  },
  paperLeft: {
    padding: theme.spacing(30, 8),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: "100vh"
  },
  cardContent: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 400
  }
}));

export default function SignInSide({ data = {}, children }) {
  const classes = useStyles();
  const largerScreen = useMediaQuery(theme => theme.breakpoints.up('sm') || theme.breakpoints.up('xs'));
  const { layout = {} } = data;
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={false} md={5} className={classes.image} style={{ backgroundImage: `url(${layout.backgroundImage})` }}>
        {largerScreen && <div className={classes.paperLeft}>
          {layout.logo && <div>
            <img
              alt="Logo"
              src={layout.logo}
              style={{ height: 70 }}
            />
          </div>}
          <Box>
            <Box>
              <div>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h1"
                  style={{ fontSize: 50, color: "#ffffff", fontWeight: 400 }}
                >
                  Welcome to WIA Portal
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontSize: 20, color: "#ffffff" }}
                >
                  Please sign in to continue
                </Typography>
              </div>
            </Box>
          </Box>
        </div>}
      </Grid>
      <Grid item xs={12} sm={8} md={7} component={Paper} elevation={6} square>
        <div className={classes.paperRight}>
          <Grid container>
            <Grid item xs={0} sm={0} md={2} lg={2}></Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              mb={3}
            >
              <div>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h1"
                >
                  Sign in
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                >
                  Sign in to internal platform
                </Typography>
              </div>
            </Box>
            <Divider />
            <Box
              mt={3}
            >
              {children}
            </Box>
            </Grid>
            <Grid item xs={0} sm={0} md={2} lg={2}></Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}