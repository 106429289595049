import React from 'react';
import { styled } from '@mui/material/styles';

import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import Timeline from '@mui/icons-material/AccessTime';
import Notifications from '@mui/icons-material/Notifications';
import Account from './Account';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function TopBar() {

  return (
    <AppBar position="fixed">
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          WIA Vendor Portal
        </Typography>
        <Box
          ml={2}
          flexGrow={1}
        />
        <IconButton color="inherit" aria-label="recent activities" component="span">
          <Timeline />
        </IconButton>
        <IconButton color="inherit" aria-label="notifications" component="span">
          <Notifications />
        </IconButton>
        <Box ml={4}>
          <IconButton color="inherit" aria-label="recent activities" component="span">
            <Account />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
