import { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Layouts from '../../../layouts';
import { Grid } from '@material-ui/core';
import Components from '../../index';
import { entityAPIService } from '../../../apis/serverActionHandler';
var pluralize = require('pluralize')


const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%'
    }
  }));

export default function PageView({ data = {} }) {
    const classes = useStyles();
    const [apiData, setApiData] = useState([]);

    const Layout = (data.layout && data.layout.id) ? Layouts[data.layout.id] : Fragment
    const { components, ...rest } = data;

    useEffect(() => {
      const fetchData = async () => {
        await entityAPIService
          .getAll(data.entity)
          .then(res => {
            const entityData = res.data
            setApiData(entityData)
          })
          .catch(err => console.log("ERROR ", err))
      }
  
      setApiData([])
      fetchData();
    }, [data && data.entity]);
  
    console.log("API DATA PAGE BUILDER", apiData)
    
    return (
      <div className={classes.root}>
        <Layout data={{ ...rest, apiData }}>
          {components && components.map((comp, index) => {
            const Component = Components[comp.type];

            return <Grid item {...comp.gridBreakPoint}>
              <Component key={index} data={{...comp, apiData}} />
            </Grid>
          })}
        </Layout>
      </div>
    )
  }