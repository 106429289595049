import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from '../../redux/store';

import { Typography } from '@material-ui/core';
import { Stack, Avatar } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import actions from '../../apis/clientActionHandler';

import Components from '../../components';
import PermissionGate from '../../components/auth/PermissionGate';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 30,
        fontWeight: 600,
        color: theme.palette.primary.main
    },
    status: {
        fontSize: 16,
        fontWeight: 400,
        color: theme.palette.primary.text
    },
    iconButton: {
        fontSize: "30px !important",
        marginTop: '2px !important',
        "&:hover": {
            cursor: "pointer"
        }
    }
}))

export default function DetailHeader({ data = {}, UIMetaData, permissionSet }) {
    const classes = useStyles();
    const { id: formName, layout } = UIMetaData;
    const { avatar, title, subTitle, buttons } = layout && layout.detailView && layout.detailView.header;

    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const routerHistory = useHistory()

    const handleButtonClick = btnOnClickEvent => {
        btnOnClickEvent.actions.forEach(action => {
            actions[action](dispatch, state, routerHistory)({ formName, data, parameters: { onClick: btnOnClickEvent } });
        });
    }

    return (
        <Stack direction="row" spacing={2} justifyContent="space-between">
            <Stack direction="row" spacing={2} alignItems="center">
                {avatar && <div>
                    <Avatar sx={{ width: 100, height: 100 }} variant="rounded" src={data.avatar} />
                </div>}
                <div>
                    <Typography className={classes.title}>
                        {data[title]}
                    </Typography>
                    <Typography className={classes.status}>
                        {data[subTitle]}
                    </Typography>
                </div>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="flex-start">
                {buttons.map(btn => {
                    const WIAButton = Components[btn.type];
                    const btnOnClickEvent = btn.events && btn.events.onClick && btn.events.onClick;
                    return (
                        <PermissionGate
                            permissionSet={permissionSet}
                            visibleCondition={btn.visibleCondition}
                            readOnlyCondition={btn.readOnlyCondition}>
                            <WIAButton
                                {...btn}
                                onClick={
                                    btnOnClickEvent ?
                                        () => handleButtonClick(btnOnClickEvent) :
                                        () => console.log("CLICKED")
                                }
                            />
                        </PermissionGate>
                    )
                })}
            </Stack>
        </Stack>
    )
}