import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import appComponents from '../index';
import { fieldId2Field } from '../../utils/util';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%'
    }
}));

const fnField = (fieldMeta) => {
    if (fieldMeta) {
        const { id, breakPoints = { xs: 12, sm: 12, md: 12, lg: 12 }, type } = fieldMeta;
        const FormInputComponent = appComponents[type] || Fragment;

        return <Grid key={id} item {...breakPoints}>
            <FormInputComponent {...fieldMeta} />
        </Grid>
    }
    return null
}

export default function FormRenderer({ model, isSubmitting, setFieldValue }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                {
                    model && model.groups ? model.groups.map((group, index) => {
                        return FormGroupsRenderer(index, group, model, isSubmitting, setFieldValue)
                    })
                        :
                        <div>
                            <Grid container spacing={2}>
                                {model && model.components && model.components.map((fieldMeta, i) => {
                                    return fnField({
                                        ...fieldMeta,
                                        isSubmitting,
                                        setFieldValue
                                    })
                                })
                                }
                            </Grid>
                        </div>
                }
            </Grid>
        </div>
    )
}

function FormGroupsRenderer(index, group, model, isSubmitting, setFieldValue) {
    const { fields, groups, breakPoints, type } = group;
    const groupFields = fieldId2Field(fields, model.fieldsH);
    const GroupComponent = appComponents[type] || Fragment;

    return (
        <Grid item {...breakPoints}>
            <GroupComponent
                key={group.id || `group_${index}`}
                title={group.label || group.title || ''}>
                <Grid container spacing={2}>
                    {groups && groups.map((group, index) => {
                        return FormGroupsRenderer(index, group, model, isSubmitting, setFieldValue)
                    })}
                    {groupFields && groupFields.map((fieldMeta, i) => {
                        return fnField({
                            ...fieldMeta,
                            isSubmitting,
                            setFieldValue
                        })
                    })}
                </Grid>
            </GroupComponent>
        </Grid>
    )
}