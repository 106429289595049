import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  windows: {}
};

const slice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal(state, action) {
      const { payload } = action;
      const { windows } = state;
      const newWindow = { ...payload.window };
      const keyIndex = Object.keys(windows).length;
      const windowId = `${payload.window.content}_${keyIndex}`;
      newWindow.id = windowId;
      newWindow.keyIndex = keyIndex;
      state.windows[windowId] = newWindow;
    },
    closeModal(state, action) {
      const { windows } = state;
      delete windows[action.payload.window.id];
    }
  }
});

export const reducer = slice.reducer;

export const openModal = (data) => (dispatch) => {
  dispatch(slice.actions.openModal(data));
};

export const closeModal = (data) => (dispatch) => {
  dispatch(slice.actions.closeModal(data));
};

export default slice;
