import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CardList from './CardList';
import CardListFilter from './CardListFilter';
import DetailHeader from './DetailHeader';
import DetailViewTabSection from './DetailViewTabSection';

const useStyles = makeStyles((theme) => ({
    container: {
        border: "1px solid #0000001f",
        padding: theme.spacing(2)
    },
    tabHeader: {
        border: "1px solid #0000001f",

    }
}))

const DetailView = ({ data = {}, children }) => {
    const classes = useStyles();
    const { id } = useParams();
    const { apiData = [] } = data;
    const [selectedData, setSelectedData] = useState({});
    
    useEffect(() => {
        const findData = () => {
            apiData.length > 0 && apiData.forEach(_apiData => {
                if (_apiData.id === parseInt(id)) setSelectedData(_apiData);
            });
        }

        findData();
    }, [id, apiData])

    console.log("id ", id, selectedData)
    return (
        <div>
            <Grid container>
                <Grid item lg={3} className={classes.container}>
                    <CardListFilter UIMetaData={data}/>
                    <CardList UIMetaData={data} dataList={apiData} />
                </Grid>
                <Grid item lg={9} className={classes.container} style={{ borderLeft: 0 }}>
                    <DetailHeader UIMetaData={data} data={selectedData} />
                    <div style={{ marginTop: 10 }}>
                        <DetailViewTabSection UIMetaData={data.layout.detailView.tabs}/>
                        {children}
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default DetailView;