import React, { useState, useEffect } from 'react';
import items from '../../../helpers/datasource/items.json'
import { Grid, Button, Stack, Typography, TextField } from '@mui/material';
import SelectedItemsList from './SelectedItemsList';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Dialog } from '@material-ui/core';
import ItemSelectPopup from './ItemsSelectPopup';

export default function ItemList() {
    const [isAddItemModelOpen, setAddItemModelOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [subTotal, setSubTotal] = useState(0.00);
    const [discount, setDiscount] = useState(0.00);
    const [shipping, setShipping] = useState(0.00);

    useEffect(() => {
        let calc = 0;
        selectedItems.forEach(item => {
            calc = calc + (item.rate * item.quantity);
        })
        setSubTotal(calc);
    })

    return (
        <div>
            <Dialog fullWidth open={isAddItemModelOpen} onClose={() => setAddItemModelOpen()}>
                <ItemSelectPopup items={items} selectedItems={selectedItems} onClose={setAddItemModelOpen} onClickItemSelect={setSelectedItems} />
            </Dialog>
            <Grid container spacing={4}>
                <Grid item lg={12}>
                    <SelectedItemsList selectedItems={selectedItems} />
                </Grid>
                <Grid item lg={6}>
                    <Stack spacing={2}>
                        <Button
                            variant="contained"
                            type="primary"
                            sx={{ width: 200 }}
                            onClick={() => setAddItemModelOpen(!isAddItemModelOpen)}
                            startIcon={<ControlPointIcon />}>
                            Add Item
                        </Button>
                        <Stack>
                        <Typography variant="body">
                            Remarks
                        </Typography>
                        <TextField
                            multiline
                            rows={4}
                        />
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item lg={6} padding={2}>
                    <Stack spacing={2}>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="body1">
                                Sub Total
                            </Typography>
                            <Typography>
                                {subTotal}
                            </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="body1">
                                    Discount
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    sx={{ width: 125 }}
                                    value={discount}
                                    type="number"
                                    onChange={(event) => setDiscount(event.target.value)}
                                />
                            </Stack>
                            <Typography>
                                {parseFloat(discount).toFixed(2)}
                            </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="body1">
                                    Shipping
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    sx={{ width: 125 }}
                                    value={shipping}
                                    type="number"
                                    onChange={(event) => setShipping(event.target.value)}
                                />
                            </Stack>
                            <Typography>
                                {parseFloat(shipping).toFixed(2)}
                            </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="h6">
                                Total
                            </Typography>
                            <Typography variant="h6">
                                {subTotal - discount + parseFloat(shipping)}
                            </Typography>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </div>
    )
}